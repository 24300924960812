import * as React from "react"
// import Form from 'react-bootstrap/Form'
// import Button from 'react-bootstrap/Button'
// import Card from 'react-bootstrap/Card'
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactsPage = () => (
  <Layout>
    <Seo title="Contactos" />
    <h1>Contactos</h1>
    <p>Entre em contacto connosco para conhecer o nosso preçário ou partilhar as suas opiniões.</p>
    <p>
      <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;
      <a href="mailto:geral@docesdarada.com">geral@docesdarada.com</a>
    </p>
    <p>
      <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;
      <span>939 192 879</span>
    </p>
    {/* <br/>
    <Card>
      <Card.Header>Formulário de contacto</Card.Header>
      <Card.Body>
        <Form name="contact" data-netlify="true">
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Nome</Form.Label>
            <Form.Control type="text" placeholder="Nome" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Endereço de e-mail</Form.Label>
            <Form.Control type="email" placeholder="E-mail" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formMessage">
            <Form.Label>Mensagem</Form.Label>
            <Form.Control as="textarea" rows={5} label="Mensagem" />
          </Form.Group>
          <input type="hidden" name="form-name" value="contact" />
          <Button type="submit" variant="light">
            Enviar
          </Button>
        </Form>
      </Card.Body>
    </Card> */}
  </Layout>
)

export default ContactsPage
